<template>
  <div>
    <div
      class="event-detail"
      v-if="EventDetail && Object.keys(EventDetail).length"
    >
      <div class="content">
        <div class="event-detail__title" v-html="EventDetail.name"></div>
      </div>
      <Breadcrumbs
        :pages="[
          {
            name: 'Мероприятия',
            link: {
              name: 'Events',
            },
          },
          {
            name: EventDetail.name,
          },
        ]"
      />
      <div class="content">
        <div class="event-detail__banner-block">
          <picture>
            <source
              :srcset="EventDetail.banner_mobile"
              media="(max-width: 767px)"
            />
            <source
              :srcset="EventDetail.banner_tablet"
              media="(max-width: 1220px)"
            />
            <source
              :srcset="EventDetail.banner_desktop"
              media="(min-width: 1221px)"
            />
            <img
              class="event-detail__banner mt-8"
              :srcset="EventDetail.banner_desktop"
            />
          </picture>
        </div>
        <div class="event-detail__date-info">
          <div class="event-detail__date-text">
            <div class="event-detail__date-item" v-if="EventDetail.date">
              <div class="event-detail__date-item-title">Дата и время</div>
              <div class="event-detail__date-item-value">
                {{ $m(EventDetail.date).format("DD MMMM") }},
                {{ $m.utc(EventDetail.date).utcOffset(+3).format("HH:mm") }}
                (Мск)
              </div>
            </div>
            <div class="event-detail__date-item" v-if="EventDetail.place">
              <div class="event-detail__date-item-title">Место</div>
              <div class="event-detail__date-item-value">
                {{ EventDetail.place }}
              </div>
            </div>
          </div>
          <div
            class="event-detail__add-calendar button button_empty-pink"
            @click="getCalendar(EventDetail.id)"
          >
            Добавить в календарь (.ics)
          </div>
          <div
            class="event-detail__add-calendar button button_empty-pink ml-8 mt-sm-4 ml-sm-0"
            @click="toRegister"
            v-if="!Object.keys(user).length"
          >
            Зарегистрироваться
          </div>
        </div>
        <div class="event-detail__timer">
          <div class="event-detail__timer-title">
            {{
              !Object.keys(timerResult).length
                ? "Мероприятие началось"
                : timerResult.months
                ? "Подключение к трансляции:"
                : "Мероприятие начнется через:"
            }}
          </div>
          <div class="event-detail__timer-date" v-if="timerResult.months">
            {{ $m(EventDetail.date).format("DD MMMM") }}
            в
            {{
              $m
                .utc(EventDetail.date)
                .utcOffset(+3)
                .subtract(10, "minutes")
                .format("HH:mm")
            }}
            (МСК)
          </div>
          <div
            v-else-if="Object.keys(timerResult).length"
            class="event-detail__timer-row"
          >
            <!-- <div class="event-detail__timer-item" v-if="timerResult.months">
              <div class="timer__value">{{ timerResult.months }}</div>
              <div class="timer__text">Месяцев</div>
            </div> -->
            <div class="event-detail__timer-item" v-if="timerResult.days > 0">
              <div class="timer__value">{{ timerResult.days }}</div>
              <div class="timer__text">
                {{ getNoun(timerResult.days, "день", "дня", "дней") }}
              </div>
            </div>
            <div class="event-detail__timer-item" v-if="timerResult.hours > 0">
              <div class="timer__value">{{ timerResult.hours }}</div>
              <div class="timer__text">
                {{ getNoun(timerResult.hours, "час", "часа", "часов") }}
              </div>
            </div>
            <div
              class="event-detail__timer-item"
              v-if="timerResult.minutes > 0"
            >
              <div class="timer__value">{{ timerResult.minutes }}</div>
              <div class="timer__text">
                {{ getNoun(timerResult.minutes, "минуту", "минуты", "минут") }}
              </div>
            </div>
            <div
              class="event-detail__timer-item"
              v-if="timerResult.seconds > 0"
            >
              <div class="timer__value">{{ timerResult.seconds }}</div>
              <div class="timer__text">
                {{
                  getNoun(timerResult.seconds, "секунду", "секунды", "секунд")
                }}
              </div>
            </div>
          </div>
          <div
            v-if="
              ((IframeAccess &&
                Object.keys(IframeAccess).length &&
                IframeAccess[`event_${EventDetail.id}`] &&
                Object.keys(IframeAccess[`event_${EventDetail.id}`]).length) ||
                EventDetail.webinar_id) &&
              !Object.keys(timerResult).length
            "
            @click="openIframe"
            class="event-detail__translation button button_white px-6"
          >
            Смотреть трансляцию
          </div>
        </div>
        <div class="event-detail__body">
          <div class="event-detail__main-info">
            <div class="event-detail__about-title">О мероприятии</div>
            <div
              class="event-detail__about-description"
              v-if="EventDetail.banner_description"
              v-html="EventDetail.banner_description"
            ></div>
            <div
              class="event-detail__iframe mb-8"
              v-if="
                ((IframeAccess &&
                  Object.keys(IframeAccess).length &&
                  IframeAccess[`event_${EventDetail.id}`] &&
                  Object.keys(IframeAccess[`event_${EventDetail.id}`])
                    .length) ||
                  EventDetail.webinar_id) &&
                !Object.keys(timerResult).length
              "
              :class="{ 'event-detail__iframe_opened': openFrame }"
            >
              <div
                @click="openFrame = false"
                v-if="openFrame"
                class="event-detail__close-btn"
              >
                <span class="button button_pink button_small">Назад</span>
                <!-- <svg
                  class="d-xl-none"
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M5.25 5.25L18.75 18.75"
                    stroke="currentColor"
                    stroke-width="1.5"
                    stroke-linecap="square"
                  ></path>
                  <path
                    d="M18.75 5.25L5.25 18.75"
                    stroke="currentColor"
                    stroke-width="1.5"
                    stroke-linecap="square"
                  ></path>
                </svg> -->
              </div>
              <iframe
                v-if="
                  IframeAccess &&
                  IframeAccess[`event_${EventDetail.id}`] &&
                  Object.keys(IframeAccess[`event_${EventDetail.id}`]).length
                "
                class="d-xl-block"
                id="userplayer"
                frameborder="0"
                :src="
                  IframeAccess[`event_${EventDetail.id}`].url +
                  '&lang=Ru-ru&disableSupport=1'
                "
                allow="camera;microphone;fullscreen;accelerometer;autoplay;clipboard-write;encrypted-media;gyroscope;picture-in-picture;display-capture;"
                allowusermedia
              ></iframe>
              <iframe
                v-else
                class="d-xl-block"
                id="userplayer"
                frameborder="0"
                :src="`https://edu.livedigital.space/room/${
                  EventDetail.webinar_id
                }/?participantName=${
                  $route.query.email || ''
                }&lang=Ru-ru&disableSupport=1`"
                allow="camera;microphone;fullscreen;accelerometer;autoplay;clipboard-write;encrypted-media;gyroscope;picture-in-picture;display-capture;"
                allowusermedia
              ></iframe>
            </div>
            <div
              v-if="
                ((IframeAccess &&
                  Object.keys(IframeAccess).length &&
                  IframeAccess[`event_${EventDetail.id}`] &&
                  Object.keys(IframeAccess[`event_${EventDetail.id}`])
                    .length) ||
                  EventDetail.webinar_id) &&
                !Object.keys(timerResult).length &&
                false
              "
              @click="openIframe"
              class="event-detail__translation button button_pink px-6 mb-4"
            >
              Смотреть трансляцию
            </div>
            <div
              v-if="
                ((IframeAccess &&
                  Object.keys(IframeAccess).length &&
                  IframeAccess[`event_${EventDetail.id}`] &&
                  Object.keys(IframeAccess[`event_${EventDetail.id}`])
                    .length) ||
                  EventDetail.webinar_id) &&
                !Object.keys(timerResult).length &&
                false
              "
              @click="openFrame = true"
              class="event-detail__translation button button_empty-pink d-none d-xl-flex ml-auto"
            >
              <span class="mr-2">
                <svg
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M9.5 9.50012L3 3.00012M15.5 9.50012L22 3.00012"
                    stroke="currentColor"
                    stroke-width="1.5"
                    stroke-miterlimit="16"
                  />
                  <path
                    d="M15.5 14.5001L22 21.0001M9.5 14.5001L3 21.0001"
                    stroke="#830051"
                    stroke-width="1.5"
                    stroke-miterlimit="16"
                  />
                  <path
                    d="M8 3.00012H3V8.00012"
                    stroke="#830051"
                    stroke-width="1.5"
                  />
                  <path
                    d="M8 21.0001H3V16.0001"
                    stroke="#830051"
                    stroke-width="1.5"
                  />
                  <path
                    d="M22 8.00012L22 3.00012L17 3.00012"
                    stroke="#830051"
                    stroke-width="1.5"
                  />
                  <path
                    d="M22 16.0001L22 21.0001L17 21.0001"
                    stroke="#830051"
                    stroke-width="1.5"
                  />
                </svg>
              </span>
              На весь экран
            </div>
            <div class="event-detail__about-title mt-md-8" v-if="lectors">
              Лекторы
            </div>
            <div v-html="lectors.content" v-if="lectors"></div>
            <!-- <InfoDrop v-for="(item, ind) in blocks" :key="ind" :item="item" /> -->
            <div
              class="event-detail__additional"
              v-html="EventDetail.additional_info"
              v-if="EventDetail.additional_info"
            ></div>
          </div>
        </div>
      </div>
      <PopupEnd
        :eventName="'enhertu-event-esmo'"
        :backUrl="{ name: this.$route.name }"
        @close="closePopup"
        v-if="popup"
      />
    </div>
    <div v-else-if="EventDetail === 404"><Choch class="inPage" /></div>
    <div v-else-if="EventDetail === false"><Error class="inPage" /></div>
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import Breadcrumbs from "../../components/pageComponents/Breadcrumbs.vue";
import InfoDrop from "../../components/pageComponents/InfoDrop.vue";
import PopupEnd from "@/components/pageComponents/PopUpEventEnd.vue";
import Choch from "@/views/404.vue";
import Error from "@/views/Error.vue";
export default {
  metaInfo() {
    if (this.EventDetail.name) {
      return {
        title: "Az-most - " + this.EventDetail.name.replace(/<[^>]*>?/gm, ""),
      };
    } else {
      return { title: "Az-most - " + this.EventDetail.name };
    }
  },
  name: "EventDetail",
  components: { Breadcrumbs, InfoDrop, Choch, Error, PopupEnd },
  data: () => ({
    popup: false,
    openFrame: false,
    timerResult: { id: 0 },
    timerResultEnd: {},
    interval: null,
  }),
  computed: {
    ...mapGetters(["user", "IframeAccess"]),
    EventDetail() {
      return {
        id: 395,
        types: [
          {
            id: 1,
            name: "Онлайн",
            code: "online",
          },
        ],
        nosology: [
          {
            id: 1,
            title: "Онкология",
            slug: "oncology",
            icon: "https://storage.yandexcloud.net/az-most.ru-storage/media/uploads/nosology/icons/2022/07/06/Frame_483407.svg",
            active: true,
          },
        ],
        lector: [
          {
            id: 61,
            name: "Румянцев Алексей Александрович",
            lector_id: "0d1e21aa-af5c-4436-b66a-4377bf2b3f9a",
            position:
              "к.м.н. , заведующий отделением химиотерапии, ФГБУ «НМИЦ онкологии им. Н.Н. Блохина» Минздрава России",
          },
          {
            id: 62,
            name: "Пашкова Елена Николаевна",
            lector_id: "aeac4599-77ac-4696-9b0b-4b4358d05d2f",
            position:
              "Зав. отделением онкогинекологии, ГАУЗ СО «Свердловский областной онкологический диспансер», г. Екатеринбург",
          },
          {
            id: 63,
            name: "Кишкина Анастасия Юрьевна",
            lector_id: "6104ce78-8c42-45fc-8834-8b96c96992da",
            position:
              "Зав. отделением онкогинекологии, ГБУЗ «Городская клиническая онкологическая больница №1 Департамента здравоохранения», г. Москва",
          },
        ],
        name: "Вебинар  «Трудный пациент с РЯ, час с экспертом»",
        description:
          "15:00 — 15:20 <strong>HRD - как биомаркер персонализированной терапии рака яичников сегодня</strong>\r\n<br>\r\nРумянцев Алексей Александрович <br>к.м.н. , заведующий отделением химиотерапии, ФГБУ «НМИЦ онкологии им. Н.Н. Блохина» Минздрава России\r\n<br><br>\r\n\r\n15:20 — 15:35  <strong>Клинический случай №1</strong>\r\n<br>\r\nПашкова Елена Николаевна <br>Зав. отделением онкогинекологии, ГАУЗ СО «Свердловский областной онкологический диспансер», г. Екатеринбург\r\n<br><br>\r\n\r\n15:35 — 15:50  <strong>Клинический случай №2</strong>\r\n<br>\r\nКишкина Анастасия Юрьевна <br>Зав. отделением онкогинекологии, ГБУЗ «Городская клиническая онкологическая больница №1 Департамента здравоохранения», г. Москва\r\n<br><br>\r\n\r\n15:50 — 16:00  <strong>Вопросы и ответы</strong>",
        banner_description:
          "15:00 — 15:20 <strong>HRD - как биомаркер персонализированной терапии рака яичников сегодня</strong>\r\n<br>\r\nРумянцев Алексей Александрович <br>к.м.н. , заведующий отделением химиотерапии, ФГБУ «НМИЦ онкологии им. Н.Н. Блохина» Минздрава России\r\n<br><br>\r\n\r\n15:20 — 15:35  <strong>Клинический случай №1</strong>\r\n<br>\r\nПашкова Елена Николаевна <br>Зав. отделением онкогинекологии, ГАУЗ СО «Свердловский областной онкологический диспансер», г. Екатеринбург\r\n<br><br>\r\n\r\n15:35 — 15:50  <strong>Клинический случай №2</strong>\r\n<br>\r\nКишкина Анастасия Юрьевна <br>Зав. отделением онкогинекологии, ГБУЗ «Городская клиническая онкологическая больница №1 Департамента здравоохранения», г. Москва\r\n<br><br>\r\n\r\n15:50 — 16:00  <strong>Вопросы и ответы</strong>",
        additional_info: "",
        date: "2023-12-05T15:00:00+03:00",
        date_end: "2023-12-05T16:00:00+03:00",
        place: "",
        hash: "6d8019b56c2ed9df3c2bc79893298d07de952e7e14c73316e116c9ef4caa63d6",
        webinar_id: "5nCrK45tZW",
        room_id: "6566df44a2f29c4928a931fb",
        external: false,
        external_link: "",
        is_active: true,
        banner_mobile: null,
        banner_tablet: null,
        banner_desktop: null,
        banner_desktop_l: null,
        banner_desktop_xl: null,
        banner_desktop_xxl: null,
      };
    },
    blocks() {
      return [this.lectors];
    },
    lectors() {
      if (this.EventDetail.lector.length) {
        let lectors_list = this.EventDetail.lector
          .map(
            (el) =>
              `<div class='lector__row'><div class='lector__title'>Докладчик</div><div class='lector__info'><div class='lector__name'>${el.name}</div><div class='lector__position'>${el.position}</div></div></div>`
          )
          .join("");
        return {
          title: "Лекторы",
          content: lectors_list,
        };
      } else {
        return null;
      }
    },
  },
  methods: {
    ...mapActions([
      "fetchEventDetail",
      "fetchEventsCalendar",
      "fetchIframeAccess",
    ]),
    async toRegister() {
      localStorage.setItem("source", `enhertu-event-esmo`);
      localStorage.setItem(
        "backUrl",
        JSON.stringify({ name: this.$route.name })
      );
      const vm = this;
      // const route = this.$router.resolve({
      //   name: "Register",
      //   query: { email: this.$route.query.email || "" },
      // });
      // window.open(route.href, "_self");
       window.open(this.$root.ssoLinks.registration, "_self");
    },
    openIframe() {
      const vm = this;
      if (!localStorage.getItem("token") && !!!Object.keys(this.user).length) {
        // if (!this.$route.query.access) {
        //   this.openFrame = true;
        // } else {
        //   // this.openFrame = true;
        //   this.popup = true;
        // }
        // this.openFrame = true;
        this.popup = true;
      } else {
        this.openFrame = true;
      }
    },
    timer() {
      let date = new Date();
      const dateEnd = this.$m
        .utc(this.EventDetail.date)
        .utcOffset(+3)
        .subtract(10, "minutes");
      const today = this.$m.utc(date).utcOffset(+3);
      const diff = today.diff(dateEnd, "seconds");
      this.$set(this, "timerResult", {});
      if (diff > 0) return;
      let months = Math.abs(diff) / 2592000;
      let fullMonths = Math.floor(months);
      let days = (months - Math.floor(months)) * 30;
      let fullDays = Math.floor(days);
      let hours = (days - Math.floor(days)) * 24;
      let fullHours = Math.floor(hours);
      let minutes = (hours - Math.floor(hours)) * 60;
      let fullMinutes = Math.floor(minutes);
      let seconds = (minutes - Math.floor(minutes)) * 60;
      let fullSeconds = Math.round(seconds);
      this.$set(this, "timerResult", {});
      if (fullMonths) {
        this.$set(this.timerResult, "months", fullMonths);
      }
      if (fullDays) {
        this.$set(this.timerResult, "days", fullDays);
      }
      if (fullHours) {
        this.$set(this.timerResult, "hours", fullHours);
      }
      if (fullMinutes) {
        this.$set(this.timerResult, "minutes", fullMinutes);
      }
      if (fullSeconds) {
        this.$set(this.timerResult, "seconds", fullSeconds);
      }
    },
    async getCalendar(id) {
      const url = await this.fetchEventsCalendar(id);
      let a = document.createElement("a");
      a.style.display = "none";
      document.body.appendChild(a);
      a.href = url;
      a.download = "event.ics";
      a.click();
      setTimeout(async () => {
        window.URL.revokeObjectURL(url);
      }, 1000);
      a.remove();
    },
    getNoun(number, one, two, five) {
      let n = Math.abs(number);
      n %= 100;
      if (n >= 5 && n <= 20) {
        return five;
      }
      n %= 10;
      if (n === 1) {
        return one;
      }
      if (n >= 2 && n <= 4) {
        return two;
      }
      return five;
    },
    closePopup() {
      this.popup = false;
    },
    webinarEnd(e) {
      if (e.origin !== document.location.origin) {
        if (e.data && e.data === "ld_finish_call") {
          if (
            !localStorage.getItem("token") &&
            !!!Object.keys(this.user).length &&
            !!this.$route.query.access
          ) {
            this.popup = true;
          }
        }
      }
    },
  },
  created() {},
  async mounted() {
    window.addEventListener("message", this.webinarEnd, false);
    this.timer();
    this.interval = setInterval(this.timer, 1000);

    const today = this.$m.utc(new Date()).utcOffset(+3);
    const dateEnd = this.$m
      .utc(this.EventDetail.date_end)
      .utcOffset(+3)
      .add(1, "hours");
    const diff = today.diff(dateEnd, "seconds");
    if (diff > 0) {
      this.$router.push({ name: "Events" });
    }
  },
  beforeDestroy() {
    clearInterval(this.interval);
    window.removeEventListener("message", this.webinarEnd, false);
  },
  watch: {
    "$route.params.id": {
      handler: async function () {
        // if (this.$route.params.id && !this.$route.query.access) {
        //   await this.fetchEventDetail({id: this.$route.params.id});
        // }
      },
      deep: true,
      immediate: true,
    },
    async timerResult() {
      if (!Object.keys(this.timerResult).length) {
        this.fetchIframeAccess(this.EventDetail.id);
        clearInterval(this.interval);
      }
    },
    async EventDetail() {
      if (this.EventDetail.external) {
        window.open(this.EventDetail.external_link, "_self");
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.timer {
  &__value {
    margin-right: 16px;
    font-family: "Roboto Slab", sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 42px;
    line-height: 48px;

    @media screen and (max-width: 767px) {
      margin-right: 0;
      font-size: 32px;
      line-height: 38px;
    }
  }

  &__text {
    font-family: "Roboto", sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 18px;
    line-height: 26px;

    @media screen and (max-width: 767px) {
      font-size: 16px;
      line-height: 22px;
    }
  }
}
.event-detail {
  padding-top: 64px;
  @media screen and (max-width: 1220px) {
    padding-top: 40px;
  }

  &__banner {
    width: 100%;
  }

  &__timer {
    margin-bottom: 40px;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    padding: 24px 37px;
    background-color: #d0006f;
    color: #fff;

    @media screen and (max-width: 1220px) {
      flex-direction: column;
      align-items: flex-start;
    }

    @media screen and (max-width: 767px) {
      padding: 16px;
    }

    &-title {
      font-family: "Roboto Slab", sans-serif;
      font-style: normal;
      font-weight: 400;
      font-size: 23px;
      line-height: 29px;
      margin-right: auto;

      @media screen and (max-width: 1220px) {
        margin-right: 0;
        margin-bottom: 32px;
      }

      @media screen and (max-width: 767px) {
        font-size: 21px;
        line-height: 26px;
      }
    }

    &-item {
      margin-right: 40px;
      display: flex;
      justify-content: flex-start;
      align-items: center;
      &:last-child {
        margin-right: 0;
      }

      @media screen and (max-width: 1220px) {
        margin-right: auto;
      }

      @media screen and (max-width: 767px) {
        flex-direction: column;
        margin-right: auto;
      }
    }

    &-row {
      display: flex;
      justify-content: flex-start;
      align-items: center;
      @media screen and (max-width: 1220px) {
        width: 100%;
      }
    }

    &-date {
      font-family: "Roboto Slab", sans-serif;
      font-style: normal;
      font-weight: 400;
      font-size: 42px;
      line-height: 48px;
      color: #ffffff;

      @media screen and (max-width: 767px) {
        font-size: 26px;
      }
    }
  }

  &__iframe {
    position: relative;
    width: 100%;
    display: none;

    & iframe {
      width: 100%;
      height: 800px;

      @media screen and (max-width: 1220px) {
        width: 100%;
        height: 100%;
      }
    }

    @media screen and (max-width: 1220px) {
      display: none;
    }
  }

  &__iframe_opened {
    display: block;
    position: fixed;
    top: 0;
    left: 0;
    z-index: 99999999;
    width: 100vw;
    height: 100vh;
    background-color: #fff;
    padding: 64px;

    & iframe {
      height: 100%;
    }

    @media screen and (max-width: 1220px) {
      padding: 0;
    }
  }

  &__close-btn {
    cursor: pointer;
    position: absolute;
    top: 24px;
    left: 24px;
    z-index: 2;

    @media screen and (max-width: 1220px) {
      top: 0;
      right: 50%;
      transform: translateX(50%);
    }
  }

  &__title {
    font-family: "Roboto Slab", sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 42px;
    line-height: 48px;
    color: #1f1f1f;

    @media screen and (max-width: 767px) {
      font-size: 24px;
      line-height: 32px;
    }
  }

  &__translation {
    max-width: 280px;
    // width: 189px;

    @media screen and (max-width: 1220px) {
      width: auto;
    }

    @media screen and (max-width: 767px) {
      width: 100%;
      max-width: none;
    }
  }

  &__date-info {
    border-left: 4px solid #830051;
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    padding: 24px;
    margin-top: 64px;
    margin-bottom: 40px;
    background-color: #f8f8f8;

    @media screen and (max-width: 1220px) {
      flex-direction: column;
      margin-top: 40px;
    }

    @media screen and (max-width: 767px) {
      margin-top: 32px;
    }
  }

  &__about-title {
    margin-bottom: 24px;
    font-family: "Roboto Slab", sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 23px;
    line-height: 29px;
    color: #3c4242;
  }

  &__about-description {
    margin-bottom: 32px;
    font-family: "Roboto", sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 22px;
    color: #656969;
  }

  &__additional {
    margin-top: 32px;
    font-family: "Roboto", sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 22px;
    color: #656969;
  }

  &__date-text {
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;

    @media screen and (max-width: 1220px) {
      flex-direction: column;
    }
  }

  &__date-item {
    margin-right: 32px;

    @media screen and (max-width: 1220px) {
      margin-right: 0;
      margin-bottom: 16px;
    }
  }

  &__date-item-title {
    margin-bottom: 4px;
    font-family: "Roboto Slab", sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    color: #b2b4b4;
  }

  &__date-item-value {
    font-family: "Roboto", sans-serif;
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;
    color: #3c4242;
  }

  &__add-calendar {
    width: 280px;
    margin-left: auto;
    @media screen and (max-width: 1220px) {
      width: 100%;
    }
  }
}
</style>

<style lang="scss">
.event-detail {
  i {
    font-style: italic !important;
  }

  b,
  strong {
    font-weight: 500 !important;
  }

  ul {
    list-style: disc;
    padding-left: 18px;
    font-family: "Roboto", sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 22px;
    color: #3c4242;
  }

  ol {
    list-style: decimal;
    padding-left: 18px;
    font-family: "Roboto", sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 22px;
    color: #3c4242;
  }

  li {
    margin-bottom: 8px;

    & > ul {
      margin-top: 8px;

      & li {
        color: #656969;
        list-style-type: none;
      }
    }
  }
}
</style>
